import { render, staticRenderFns } from "./AddGroupsModal.vue?vue&type=template&id=becfcdda&scoped=true&"
import script from "./AddGroupsModal.vue?vue&type=script&lang=ts&"
export * from "./AddGroupsModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddGroupsModal.vue?vue&type=style&index=0&id=becfcdda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "becfcdda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardActions,VCardText,VCardTitle,VCol,VIcon,VRow})
